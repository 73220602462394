<template>
    <div>
        <v-card>
            <v-expansion-panels v-if="expand">
                <v-expansion-panel v-if="selectedItem.order_id">
                    <v-expansion-panel-header>
                        Ordem Relacionada</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-card>
                            <PeriodConciliationOrdersDatatable
                                :Headers="headersOrders"
                                :Items="order"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.moviment_id">
                    <v-expansion-panel-header
                        >Movimentação Sistema
                        Relacionada</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-card>
                            <PeriodConciliationMovimentsDatatable
                                :Headers="headersMoviments"
                                :Items="moviment"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.bank_moviment_id">
                    <v-expansion-panel-header
                        >Movimentação Bancária
                        Relacionada</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-card>
                            <PeriodConciliationBankMovimentsDatatable
                                :Headers="headersBankMoviments"
                                :Items="bankMoviment"
                                :ToLinked="false"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.annotated_id">
                    <v-expansion-panel-header
                        >Anotado Relacionado</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-card>
                            <PeriodConciliationAnnotatedDatatable
                                :Headers="headersAnnotated"
                                :Items="annotated"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.closure_id">
                    <v-expansion-panel-header
                        >Fechamento Relacionado</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-card>
                            <PeriodConciliationClosureDatatable
                                :Headers="headersClosure"
                                :Items="closure"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.transaction_id">
                    <v-expansion-panel-header
                        >Transação Relacionada</v-expansion-panel-header
                    >
                    <v-expansion-panel-content v-if="credit">
                        <v-card>
                            <PeriodConciliationTransactionsDatatable
                                :Headers="headersTransactionsCredit"
                                :Items="transaction"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                        <v-card>
                            <PeriodConciliationTransactionsDatatable
                                :Headers="headersTransactionsTrade"
                                :Items="transaction"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                    v-if="
                        selectedItem.linked_to < selectedItem.total_vinculations
                    "
                >
                    <v-expansion-panel-header class="font-weight-black"
                        >Vinculações</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-select
                            :clearable="true"
                            v-model="search_in"
                            clear-icon="mdi-close"
                            label="Registro"
                            :items="items"
                            item-disabled="disabled"
                            item-text="type"
                            item-value="id"
                            outlined
                        ></v-select>
                        <v-row align="center" justify="space-between">
                            <v-btn
                                color="success"
                                type="submit"
                                @click="
                                    setDialogManualLow({
                                        multiples: false,
                                        show: true,
                                        items: [
                                            {
                                                id: selectedItem.id,
                                                linked_to:
                                                    selectedItem.linked_to,
                                            },
                                        ],
                                        tab_origin: selectedItem.tab_origin,
                                    })
                                "
                            >
                                Baixa Manual
                            </v-btn>
                            <v-btn
                                color="primary"
                                type="submit"
                                @click="getPeriodsVinculations()"
                            >
                                Buscar Fora do Período</v-btn
                            >
                            <v-btn
                                text
                                type="submit"
                                center
                                @click="getRecords()"
                            >
                                Buscar Registros</v-btn
                            >
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="selectedItem.type_low">
                    <v-expansion-panel-header class="font-weight-black"
                        >Baixa Manual</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md6>
                                        <v-select
                                            label="Tipo de Baixa"
                                            :items="lowTypes"
                                            v-model="selectedItem.type_low"
                                            readonly
                                            item-text="description"
                                            item-value="id"
                                            data-vv-name="low_type"
                                            outlined
                                        ></v-select>
                                    </v-flex>

                                    <v-flex xs12 sm6 md6>
                                        <v-text-field
                                            label="Observação"
                                            readonly
                                            v-model="
                                                selectedItem.observation_low
                                            "
                                            outlined
                                            clear-icon="mdi-close"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-dialog
            :value="dialogManualLow"
            @click:outside="closeDialogManualLow()"
        >
            <PeriodsConciliationLowDialog />
        </v-dialog>
        <v-dialog :value="linkRecordDialog" @click:outside="closeDialogLink" @keydown.esc="closeDialogLink">
            <PeriodConciliationOrdersDatatable
                v-if="search_in == 0"
                :Headers="headersOrders"
                :Items="itemsToLinked"
                :TotalItems="totalItemsToLinked"
                :ToLinked="true"
            />
            <PeriodConciliationTransactionsDatatable
                v-if="search_in == 1 && credit"
                :Headers="headersTransactionsCredit"
                :Items="itemsToLinked"
                :ToLinked="true"
                :TotalItems="totalItemsToLinked"
            />
            <PeriodConciliationTransactionsDatatable
                v-if="search_in == 1 && !credit"
                :Headers="headersTransactionsTrade"
                :Items="itemsToLinked"
                :ToLinked="true"
                :TotalItems="totalItemsToLinked"
            />

            <PeriodConciliationMovimentsDatatable
                v-if="search_in == 2"
                :Headers="headersMoviments"
                :Items="itemsToLinked"
                :TotalItems="totalItemsToLinked"
                :ToLinked="true"
            />
            <PeriodConciliationBankMovimentsDatatable
                v-if="search_in == 3"
                :Headers="headersBankMoviments"
                :Items="itemsToLinked"
                :TotalItems="totalItemsToLinked"
                :ToLinked="true"
            />
            <PeriodConciliationAnnotatedDatatable
                v-if="search_in == 4"
                :Headers="headersAnnotated"
                :Items="itemsToLinked"
                :TotalItems="totalItemsToLinked"
                :ToLinked="true"
            />
            <PeriodConciliationClosureDatatable
                v-if="search_in == 5"
                :Headers="headersClosure"
                :Items="itemsToLinked"
                :TotalItems="totalItemsToLinked"
                :ToLinked="true"
            />
        </v-dialog>
        <v-dialog :value="dialogPeriod" @click:outside="closeDialogPeriod" @keydown.esc="closeDialogPeriod">
            <PeriodsConciliationDialog />
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import {
  defaultErrorNotification,
} from "@constants";
export default {
    name: 'PeriodConciliationVinculationsDialog',
    computed: {
        ...mapState('periods_conciliation_low_dialog', [
            'closeAllDialogsManualLow',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', [
            'closure',
            'transaction',
            'moviment',
            'lowTypes',
            'order',
            'annotated',
            'bankMoviment',
            'expand',
            'selectedItem',
            'dialogDropManually',
            'credit',
            'linkRecordDialog',
            'items',
            'itemsToLinked',
            'totalItemsToLinked',
            'pagination',
        ]),
        ...mapState('periods_conciliation_dialog', ['dialogPeriod']),

        ...mapFields('period_conciliation_dialog_vinculation', [
            'search_in',
            'low_type',
            'observation_low',
        ]),
        ...mapState('period_conciliation_orders', { headersOrders: 'headers' }),
        ...mapState('period_conciliation_moviments', {
            headersMoviments: 'headers',
        }),
        ...mapState('period_conciliation_bank_moviments', {
            headersBankMoviments: 'headers',
        }),
        ...mapState('period_conciliation_annotated', {
            headersAnnotated: 'headers',
        }),
        ...mapState('period_conciliation_transactions', {
            headersTransactionsTrade: 'headersTrade',
            headersTransactionsCredit: 'headersCredit',
        }),
        ...mapState('period_conciliation_closures', {
            headersClosure: 'headers',
        }),
    },
    data: () => ({
        dialogManualLow: false,
        valid: false,
    }),
    created() {
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (this[field]) {
                    return true
                } else {
                    return false
                }
            },
        })
    },
    methods: {
        setDialogManualLow(payload) {
            console.log(payload)
            console.log(this.selectedItem)
            this.dialogManualLow = true
            this.showDialogManualLow(payload)
        },
        closeDialogManualLow() {
            this.dialogManualLow = false
            this.setCloseDialogManualLow()
        },
        getRecords() {
            if(this.search_in == 6){
                this.showNotification(defaultErrorNotification("Nenhum tipo de vinculação selecionado"))
            }else{
                this.setPeriod({ period: this.selectedItem.id_period_conciliation })
                this.setCallGetRecords()
                this.showLinkRecordDialog()
                this.getRecordsRelated()
            }
        },
        getPeriodsVinculations() {
            console.log(this.search_in)
            if(this.search_in == 6){
                this.showNotification(defaultErrorNotification("Nenhum tipo de vinculação selecionado"))
            }else{
                this.showDialogPeriod()
            // this.getPeriods()
            // this.getVinculations()
            }
        },

        setTotalVinculations() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.updateTotalVinculations()
                }
            })
        },
        ...mapMutations('period_conciliation', [
            'showNotification',
        ]),
        ...mapMutations('periods_conciliation_low_dialog', [
            'showDialogManualLow',
        ]),
        ...mapActions('periods_conciliation_low_dialog', [
            'setCloseDialogManualLow',
        ]),
        ...mapMutations('period_conciliation_dialog_vinculation', [
            'showDialogDrop',
            'closeDialogLink',
            'setCallGetRecords',
            'showLinkRecordDialog',

        ]),
        ...mapActions('period_conciliation_dialog_vinculation', [
            'getVinculations',
            'getRecordsRelated',
            'updateTotalVinculations',
            'setPeriod',
        ]),
        ...mapActions('periods_conciliation_dialog', ['showDialogPeriod']),
        ...mapMutations('periods_conciliation_dialog', ['closeDialogPeriod',]),
    },
    components: {
        PeriodsConciliationLowDialog: () =>
            import(
                '../periods_conciliation_low_dialog/PeriodsConciliationLowDialog.vue'
            ),
        PeriodsConciliationDialog: () =>
            import(
                '../periods_conciliation_dialog/PeriodsConciliationDialog.vue'
            ),
        PeriodConciliationOrdersDatatable: () =>
            import(
                '../period_conciliation_orders/PeriodConciliationOrdersDatatable.vue'
            ),
        PeriodConciliationMovimentsDatatable: () =>
            import(
                '../period_conciliation_moviments/PeriodConciliationMovimentsDatatable.vue'
            ),
        PeriodConciliationBankMovimentsDatatable: () =>
            import(
                '../period_conciliation_bank_moviments/PeriodConciliationBankMovimentsDatatable.vue'
            ),
        PeriodConciliationAnnotatedDatatable: () =>
            import(
                '../period_conciliation_annotated/PeriodConciliationAnnotatedDatatable.vue'
            ),
        PeriodConciliationTransactionsDatatable: () =>
            import(
                '../period_conciliation_transactions/PeriodConciliationTransactionDatatable.vue'
            ),
        PeriodConciliationClosureDatatable: () =>
            import(
                '../period_conciliation_closures/PeriodConciliationClosureDatatable.vue'
            ),
    },
    watch: {
        closeAllDialogsManualLow: {
            handler() {
                this.dialogManualLow = false
            },
        },
    },
}
</script>